import React from "react";
import Lightbox from "react-image-lightbox";
import classNames from "classnames";

import "react-image-lightbox/style.css";
import "./ProjectCard.scss";

export default function ProjectCard({ year, title, images }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  const yearStyle = classNames({
    "project-card-year": year,
    "project-card-year-clear": !year,
  });

  return (
    <div className="project-card-wrapper" onClick={() => setIsOpen(true)}>
      <div className="project-card-title">{title}</div>
      <div>
        <div className={yearStyle}>{year}</div>
        <img
          src={images[0].replace("/1.jpg", "/1_.jpg")}
          className="project-card-image"
          alt=""
        />
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}
