import Button from "./Button";
import { scroll } from "./Scroll";

import introJpg from "./4-gost_1440.jpg";
import "./Intro.scss";

export default function Intro() {
  function onClick() {
    console.log(1);
    scroll("projects");
  }

  return (
    <div className="intro-wrapper">
      <div className="intro-jpg-container">
        <img src={introJpg} className="intro-jpg" alt="" />
        <div className="intro-text">
          <div className="intro-text-container">
            <div className="intro-text-name">sas style studio</div>
            <div className="intro-text-title">Студия дизайна интерьера</div>
            <div className="intro-text-subtitle">
              интерьер любой сложности от классики до авангарда
            </div>
            {/* <Link
              activeClass="active"
              to="firstInsideContainer"
              spy={true}
              smooth={true}
              duration={250}
              containerId="containerElement"
              style={{ display: "inline-block", margin: "20px" }}
            > */}
            <Button onClick={onClick}>проекты</Button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
}
