export function scroll(id) {
  console.log(2);
  let contact = document.getElementById(id);
  contact && contact.scrollIntoView({ behavior: "smooth", block: "start" });
}

export default function Scroll({ id, children }) {
  return (
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        scroll(id);
      }}
    >
      {children}
    </a>
  );
}
