import "./Leader.scss";

export default function Leader({ photo, title, name, about, bye }) {
  return (
    <div className="leader-wrapper">
      <img src={photo} className="leader-photo" alt="" />
      <div className="leader-text-wrapper">
        <div className="leader-text-title">{title}</div>
        <div className="leader-text-name">{name}</div>
        <div className="leader-text-about">
          {about.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
        <div className="leader-text-bye">{bye}</div>
      </div>
    </div>
  );
}
