import Button from "./Button";
import ProjectCard from "./ProjectCard";
import { scroll } from "./Scroll";

// import {
//   Link,
//   DirectLink,
//   Element,
//   Events,
//   animateScroll as scroll,
//   scrollSpy,
//   scroller,
// } from "react-scroll";

import "./Projects.scss";

function getUrls(folderName, amount) {
  let arr = [];
  for (var i = 1; i <= amount; i++) {
    arr.push("images/" + folderName + "/" + i + ".jpg");
  }
  return arr;
}

const kv1 = getUrls("kv1", 5);
const kv2 = getUrls("kv2", 14);
const kv3 = getUrls("kv3", 15);
const kv4 = getUrls("kv4", 5);
const autocad = getUrls("autocad", 17);
const kv5 = getUrls("kv5", 15);
const kv6 = getUrls("kv6", 3);
const kv7 = getUrls("kv7", 3);
const kv8 = getUrls("kv8", 10);
const kv9 = getUrls("kv9", 4);
const kv10 = getUrls("kv10", 3);
const kv11 = getUrls("kv11", 19);
const kv12 = getUrls("kv12", 12);
const kv13 = getUrls("kv13", 11);
const kv14 = getUrls("kv14", 23);
const kv15 = getUrls("kv15", 17);
const kv16 = getUrls("kv16", 29);
const kv17 = getUrls("kv17", 18);
const kv18 = getUrls("kv18", 15);
const kv19 = getUrls("kv19", 22);
const kv20 = getUrls("kv20", 21);
const kv21 = getUrls("kv21", 4);

export default function Projects() {
  function onClick() {
    scroll("services");
  }

  return (
    // <Element name="firstInsideContainer">
    <div className="projects-wrapper" id="projects">
      <div className="projects-title">наши проекты</div>
      <div className="projects-cards">
        <ProjectCard year="2024" title="Кабинет" images={kv21} />
        <ProjectCard
          year="2024"
          title="Загородный дом, 170 м.кв"
          images={kv20}
        />
        <ProjectCard
          year="2023"
          title="Квартира 140 м.кв, Санкт-Петербург"
          images={kv19}
        />
        <ProjectCard year="2022" title="Москва ЖК Крылья" images={kv15} />
        <ProjectCard year="2022" title="ЖК Скандинавия Москва" images={kv14} />
        <ProjectCard year="2022" title="Квартира 86 м.кв, Сочи" images={kv17} />
        <ProjectCard
          year="2023"
          title="Квартира 40 м.кв, Санкт-Петербург"
          images={kv18}
        />
        <ProjectCard
          year="2022"
          title="Квартира 86 м.кв, Санкт-Петербург"
          images={kv16}
        />
        <ProjectCard year="2022" title="Загородный дом Репино" images={kv13} />
        <ProjectCard
          year="2022"
          title="Загородный дом Барвиха Лайт"
          images={kv12}
        />
        <ProjectCard title="Квартира 85 м.кв" images={kv11} />
        <ProjectCard title="ЖК Ботаника, Санкт-Петербург" images={kv9} />
        <ProjectCard title="Санузел Аляска" images={kv10} />
        <ProjectCard title="Многопрофильная клиника, Карелия" images={kv8} />
        <ProjectCard year="2018" title="Клиника Академика" images={kv7} />
        <ProjectCard title="Загородный дом, Карелия" images={kv5} />
        <ProjectCard title="Кафе итальянской кухни" images={kv6} />
        <ProjectCard year="2020" title="Пушкин" images={kv3} />
        <ProjectCard
          year="2021"
          title="Бильярдная, Санкт-Петербург"
          images={kv4}
        />
        <ProjectCard year="2019" title="Петрозаводск" images={kv2} />
        <ProjectCard year="2013" title="Санкт-Петербург" images={kv1} />

        <ProjectCard title="Autocad" images={autocad} />
      </div>
      <Button colorScheme="white" onClick={onClick}>
        услуги
      </Button>
    </div>
  );
}
