import "./Feedbacks.scss";

export default function Feedbacks() {
  return (
    <div className="feedbacks-wrapper" id="feedbacks">
      <div className="feedbacks-content">
        <div className="feedbacks-title">Отзывы</div>
        <div className="feedbacks-body">
          <div className="feedbacks-body-item">
            <div className="feedbacks-card-image-container">
              <div>
                <img
                  src="images/feedbacks/1.jpg"
                  className="feedbacks-card-image"
                  alt=""
                  width="100"
                />
              </div>
              <div className="feedbacks-card-name">Денис</div>
            </div>
            <div className="feedbacks-card-text">
              Мы в восторге от проекта и реализации! Редко когда такое говорим,
              но это идеально. Буквально все специалисты словили, смогли угадать
              то, что нам нужно и сделать даже лучше. Команда разработала дизайн
              четко по нашим запросам. Очень понравилось работать со всей
              командой. Собранные, пунктуальные, знающие свое дело, да просто
              приятные люди в общении. Сдали проект раньше, а расценки очень
              порадовали.
            </div>
          </div>

          <div className="feedbacks-body-item">
            <div className="feedbacks-card-image-container">
              <div>
                <img
                  src="images/feedbacks/2.jpg"
                  className="feedbacks-card-image"
                  alt=""
                  width="100"
                />
              </div>
              <div className="feedbacks-card-name">Мария</div>
            </div>
            <div className="feedbacks-card-text">
              Ремонт всегда требует много энергии, нервов. Тем боллее, если это
              ремонт твоей клиники – твоего детища, это вдвойне нервотрепка. Но
              благодаря студии «Sas-style» я была избавлена от лишнего стресса,
              специалисты компании продумали всё мелочи еще на этапе дизайна –
              настоящие профи. Очень ответственные, скрупулезные и очень
              терпеливые, они выполнили все работы на высочайшем уровне и в
              поставленные сроки. Спасибо вам огромное!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
