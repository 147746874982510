import Scroll from "./Scroll";
import logo from "./logo.svg";

import "./Header.scss";

export default function Header() {
  return (
    <div className="header-container">
      <img src={logo} className="app-logo" alt="sas-style logo" />
      <div>
        <span>
          <Scroll id="description">Главная</Scroll>
        </span>
        <span>
          <Scroll id="about-us">О нас</Scroll>
        </span>
        <span>
          <Scroll id="projects">Проекты</Scroll>
        </span>
        <span>
          <Scroll id="services">Услуги</Scroll>
        </span>
        <span>
          <Scroll id="feedbacks">Отзывы</Scroll>
        </span>
        <span>
          <Scroll id="contacts">Контакты</Scroll>
        </span>
      </div>
    </div>
  );
}
