import React from "react";
import axios from "axios";
import Scroll from "./Scroll";

import phone from "./phone.svg";
import vk from "./vk.svg";
import instagram from "./instagram.svg";
import email from "./email.svg";
import Button from "./Button";

import "./Contacts.scss";

export default function Contacts() {
  const [name, setName] = React.useState("");
  const [cont, setCont] = React.useState("");
  const [done, setDone] = React.useState(false);

  function handleSubmit() {
    axios
      .post("/send.php", {
        fio: name,
        email: cont,
      })
      .then(function (response) {
        if (response) setDone(true);
      })
      .catch(function (error) {});
  }

  return (
    <div className="contacts-wrapper" id="contacts">
      <div className="contacts-fixed-button-wrapper">
        <Scroll id="contacts">
          <img src={phone} className="contacts-fixed-button-image" />
        </Scroll>
      </div>
      <div className="contacts-content">
        <div className="contacts-title">Контакты</div>
        {done ? (
          <div className="contacts-done">спасибо, мы с вами свяжемся</div>
        ) : (
          <form onSubmit={handleSubmit} className="contacts-form">
            <input
              className="contacts-form-input"
              type="text"
              placeholder="Укажите имя"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <input
              className="contacts-form-input"
              type="text"
              placeholder="Укажите телефон"
              value={cont}
              onChange={(e) => {
                setCont(e.target.value);
              }}
            />

            <Button colorScheme="white" onClick={handleSubmit}>
              Отправить
            </Button>
          </form>
        )}
        <div className="contacts-body">
          <div className="contacts-body-item">
            <img src={phone} className="contacts-body-item-icon" alt="" />
            <a href="tel:+79210941747">+7-921-0941747</a>
            Аксана
          </div>

          <div className="contacts-body-item">
            <img src={vk} className="contacts-body-item-icon" alt="" />
            <a href="https://vk.com/id19247377" target="_blank">
              vk.com/id19247377
            </a>
          </div>

          {/* <div className="contacts-body-item">
          <img src={instagram} className="contacts-body-item-icon" alt="" />
          <a
            href="https://www.instagram.com/aksana_sitnikova_design/"
            target="_blank"
          >
            aksana_sitnikova_design
          </a>
        </div> */}
          <div className="contacts-body-item">
            <img src={email} className="contacts-body-item-icon" alt="" />
            <a href="mailto:your_style@mail.ru">your_style@mail.ru</a>
          </div>
        </div>
      </div>
    </div>
  );
}
