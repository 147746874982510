import "./Button.scss";

// mainColor - основной цвет текста
// secondColor - цвет текста при наведении

export default function Button({ colorScheme, children, onClick }) {
  let myStyle;

  if (colorScheme === "default") {
    myStyle = {
      "--main-color": "white",
      "--hover-color": "black",
      "--main-bg-color": "#0000",
      "--hover-bg-color": "white",
      "--main-border-color": "white",
      "--hover-border-color": "black",
    };
  } else if (colorScheme === "white") {
    myStyle = {
      "--main-color": "black",
      "--hover-color": "white",
      "--main-bg-color": "white",
      "--hover-bg-color": "black",
      "--main-border-color": "black",
      "--hover-border-color": "white",
    };
  }

  return (
    <div className="button-wrapper" style={myStyle} onClick={onClick}>
      {children}
    </div>
  );
}

Button.defaultProps = { colorScheme: "default" };
