import "./BenefitsCard.scss";

export default function BenefitsCard({ icon, title, text }) {
  return (
    <div className="benefit-card-wrapper">
      <img src={icon} className="benefit-card-icon" alt="" />
      <div className="benefit-card-title">{title}</div>
      <div className="benefit-card-text">{text}</div>
    </div>
  );
}
