import Leader from "./Leader";
import AksanaJpg from "./Aksana.jpg";
import LyudaJpg from "./Lyuda.jpg";
import TanyaJpg from "./Tanya.jpg";

export default function AboutUs() {
  return (
    <div id="about-us">
      <Leader
        photo={AksanaJpg}
        title="Арт директор, дизайнер"
        name="Аксана Ситникова"
        about={[
          "Санкт-Петербургская государственная художественно- промышленная академия им. А.Л. Штиглица, факультет художественное проектирование интерьеров.",
        ]}
        bye=""
      />
      <Leader
        photo={LyudaJpg}
        title="Дизайнер, визуализатор"
        name="Людмила Разумова"
        about={[
          "Петрозаводский государственный университет, строительный факультет",
        ]}
        bye=""
      />
      <Leader
        photo={TanyaJpg}
        title="Визуализатор-3d, дизайнер"
        name="Татьяна Стасюк"
        about={[
          "Политехнический институт , факультет промышленного и гражданского строительства",
        ]}
        bye=""
      />
    </div>
  );
}
