import Button from "./Button";
import { scroll } from "./Scroll";

import "./Description.scss";

export default function Description() {
  function onClick() {
    scroll("about-us");
  }

  return (
    <div className="description-wrapper" id="description">
      <div className="description-title">
        СТУДИЯ ДИЗАЙНА И АРХИТЕКТУРНОГО ПРОЕКТИРОВАНИЯ
      </div>
      <div className="description-name">SAS STYLE STUDIO</div>
      <div className="description-text">
        <p>
          Мы рады приветствовать вас на нашем сайте. Вот уже более 10 лет мы
          стараемся сделать ваш дом уютным стильным и функциональным.
        </p>
        <p>
          Наша работа начинается со звонка к нам, дизайнер выезжает на замер и
          мы начинаем творить для вас пространство.
        </p>
      </div>
      <Button colorScheme="white" onClick={onClick}>
        ЧИТАТЬ ПОДРОБНЕЕ О БЮРО
      </Button>
      <div className="description-world-wrapper">
        <div className="description-world-header">
          Интерьер любой сложности от классики до авангарда
        </div>
        <div className="description-world-text copy-text">
          Миссия нашего бюро — создавать уникальные пространства, сотрудничая
          только с лучшими специалистами, клиентами и мастерами. Мы продолжаем
          внедрять передовые технологии в нашу практику и стремимся к искусству
          и моде для вдохновения. Каждый проект создается с учетом потребностей
          и характера клиента.
        </div>
      </div>
    </div>
  );
}
