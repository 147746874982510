import Button from "./Button";
import BenefitsCard from "./BenefitsCard";
import { scroll } from "./Scroll";

import development from "./development.svg";
import textPapersStack from "./text-papers-stack.svg";
import design from "./design.svg";
import paint from "./paint.svg";
import director from "./director.svg";
import equipment from "./equipment.svg";

import "./Services.scss";

export default function Services() {
  function onClick() {
    scroll("projects");
  }

  return (
    <div className="services-wrapper" id="services">
      <div className="services-title">УСЛУГИ</div>
      <div className="services-cards">
        <BenefitsCard
          icon={development}
          title="Перепланировка (изменение старой и создание новой)"
          text="Мы занимаемся полной перепланировкой пространства под ваши потребности и пожелания"
        />

        <BenefitsCard
          icon={textPapersStack}
          title="Согласование перепланировки"
          text="Мы помогаем согласовать перепланировку для последующей продажи недвижимости"
        />

        <BenefitsCard
          icon={design}
          title="Разработка дизайн-проекта (эскизный проект изображение 3д, пространственно- планировочные решения, планы потолков, развертки стен, разрезы, планы по электрике и освещению)"
          text="Полный цикл дизайн-проекта, с технической информацией для строительной бригады. Так же визуализация помещений в двух вариантах программ в зависимости от бюджета"
        />

        <BenefitsCard
          icon={paint}
          title="Подбор материалов (отделочные материалы и декор)"
          text="Мы подбираем для вас отделочные материалы в соответствии с проектом и даем рекомендации по приобретению"
        />

        <BenefitsCard
          icon={director}
          title="Авторский надзор"
          text="Это отдельно оплачиваемая услуга, которую вы заказываете по желанию. Возможны варианты онлайн контроля"
        />

        <BenefitsCard
          icon={equipment}
          title="Разработка нестандартного оборудования"
          text="В случае работы над проектом общественного помещения (кафе, рестораны, офисы) , вам будет предложена индивидуальная разработка оборудования под потребности помещения"
        />
      </div>
      <Button colorScheme="white" onClick={onClick}>
        проекты
      </Button>
    </div>
  );
}
