import Header from "./Header";
import Intro from "./Intro";
import Description from "./Description";
import Projects from "./Projects";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Feedbacks from "./Feedbacks";
import Contacts from "./Contacts";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <Header />
      <Intro />
      <Description />
      <Projects />
      <AboutUs />
      <Services />
      <Feedbacks />
      <Contacts />
    </div>
  );
}

export default App;
